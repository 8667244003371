import {
    zhuyeData,
    chanpinList,
    chanpinDetail,
    caidanList,
    Token,
    getLOGIN,
    LianXi
} from './api'
import {
    request,
    METHOD
} from '@/utils/request'
export async function getZhuYeList(data) {
    return request(zhuyeData, METHOD.POST, data)
}
export async function login(data) {
    return request(getLOGIN, METHOD.POST, data)
}
export async function getCPList(data) {
    return request(chanpinList, METHOD.POST, data)
}
export async function getCPDetail(data) {
    return request(chanpinDetail, METHOD.POST, data)
}
export async function getCaiDanList() {
    return request(caidanList, METHOD.POST)
}
export async function getToken() {
    return request(Token, METHOD.POST)
}
export async function getLianXi(data) {
    return request(LianXi, METHOD.POST, data)
}