<template>
  <!-- 头部整体盒子 -->
  <div id="header" class="container-fuild">
    <!-- 电脑导航 -->
    <div class="header-nav container hidden-xs">
      <!-- 导航logo -->
      <div class="header-nav-logo" @click="ClickLogo">
        <img src="@/assets/logo.png" />
        <span>趣谷科技</span>
      </div>
      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <template v-for="(item, index) in navList">
          <li
            v-if="item.Bm_HMXT_a040000 == 'SYBM'"
            :key="index"
            :class="item.Ms == navIndex ? 'active' : ''"
            @click="navClick(index, item.Ms)"
          >
            <!-- <router-link :to="{ name: item.Ms }"> -->
            <span>
              {{ item.Ms }}
              <i class="underline"></i>
            </span>
            <!-- </router-link> -->
          </li>
        </template>
      </ul>
      <div v-if="!sfdenglu" class="login">
        <p @click="ShowLogin">登录</p>
      </div>
      <div v-else class="login">
        <img class="toux" :src="url + '/tupian/' + yhtx" alt="" />
        <a-dropdown :placement="'bottomCenter'">
          <a-menu slot="overlay">
            <a-menu-item key="1">
              <p class="tuichu" @click="TuiChuLogin">退出登录</p></a-menu-item
            >
          </a-menu>
          <p>{{ yhms }}</p>
        </a-dropdown>
      </div>
    </div>
    <a-modal v-model="loginvis" title="登录" :footer="null">
      <a-form @submit="onSubmit" :form="form">
        <a-form-item>
          <a-input
            autocomplete="autocomplete"
            size="large"
            placeholder="请输入账号"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入账户名',
                    whitespace: true,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
            size="large"
            placeholder="请输入密码"
            autocomplete="autocomplete"
            type="password"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码',
                    whitespace: true,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button
            style="width: 100%; margin-top: 24px"
            size="large"
            htmlType="submit"
            type="primary"
            >登录</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="lianxivis" title="联系我们" :footer="null">
      <a-form @submit="TiJiaoLianXi" :form="lianxi">
        <a-form-item>
          <a-input
            autocomplete="autocomplete"
            size="small"
            placeholder="联系姓名"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入姓名名',
                    whitespace: true,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            autocomplete="autocomplete"
            size="small"
            placeholder="联系方式"
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入联系方式',
                    whitespace: true,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="phone" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-textarea
            autocomplete="autocomplete"
            size="small"
            placeholder="联系原由"
            v-decorator="[
              'yuanyin',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入联系原由',
                    whitespace: true,
                  },
                ],
              },
            ]"
          >
          </a-textarea>
        </a-form-item>
        <a-form-item>
          <a-textarea
            autocomplete="autocomplete"
            size="small"
            placeholder="公司地址"
            v-decorator="[
              'dizhi',
              {
                rules: [
                  {
                    required: false,
                    message: '请输入公司地址',
                    whitespace: true,
                  },
                ],
              },
            ]"
          >
          </a-textarea>
        </a-form-item>
        <a-form-item>
          <a-button
            style="width: 100%; margin-top: 24px"
            size="large"
            htmlType="submit"
            type="primary"
            >提交</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { getCaiDanList, getLianXi, login } from "../services/user";
export default {
  name: "Header",
  data() {
    return {
      navIndex: sessionStorage.getItem("navIndex")
        ? sessionStorage.getItem("navIndex")
        : "主页",
      menuName: "主页",
      form: this.$form.createForm(this),
      lianxi: this.$form.createForm(this),
      navList: [],
      loginvis: false,
      lianxivis: false,
      //是否登录
      sfdenglu: false,
      yhms: "",
      yhtx: "",
      url: "",
    };
  },
  computed: {
    todenglu() {
      return this.$store.state.denglu;
    },
    tolianxi() {
      return this.$store.state.lianxi;
    },
  },
  created() {
    if (localStorage.getItem("gw_dzbd")) {
      this.url = localStorage.getItem("gw_dzbd");
    }
    getCaiDanList().then((res) => {
      if (res.data.BianHao == "0000") {
        let caidanList = res.data.JieGuo;
        for (let i = 0; i < caidanList.length; i++) {
          if (caidanList[i].IsBeforeShow == "1") {
            this.navList.push(caidanList[i]);
          }
        }
        // this.$forceUpdate();
      }
    });
    if (localStorage.getItem("gw_yhid")) {
      this.sfdenglu = true;
    }
    this.HuoQuYongHu();
  },
  mounted() {},
  watch: {
    $route(val) {
      this.navIndex = val.name;
      sessionStorage.setItem("navIndex", val.name);
      this.menuName = val.name;
    },
    todenglu: {
      handler(val) {
        this.loginvis = true;
      },
    },
    tolianxi(val) {
      this.lianxivis = true;
    },
  },
  methods: {
    //获取登录用户信息
    HuoQuYongHu() {
      if (localStorage.getItem("gwYh")) {
        this.yhms = localStorage.getItem("gwYh");
      }
      if (localStorage.getItem("yhtoux")) {
        this.yhtx = localStorage.getItem("yhtoux");
      }
    },
    navClick(index, name) {
      this.$router.push({ name: name });
    },
    ClickLogo() {
      this.$router.push({ name: "主页" });
    },
    ShowLogin() {
      this.loginvis = true;
    },
    TuiChuLogin() {
      let that = this;
      this.$confirm({
        title: "确认退出吗?",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          that.sfdenglu = false;
          localStorage.removeItem("gw_yhid");
          localStorage.removeItem("gwYh");
          localStorage.removeItem("gwyhjs");
          localStorage.removeItem("gwYhbm");
          localStorage.removeItem("gw_jsms");
          localStorage.removeItem("yhtoux");
          that.$router.push({ name: "主页" });
        },
        onCancel() {},
      });
    },
    onSubmit() {
      let name = this.form.getFieldValue("name");
      let password = this.form.getFieldValue("password");
      login(
        this.$qs.stringify({
          zhanghao: name,
          mm: password,
        })
      ).then(this.afterLogin);
    },
    TiJiaoLianXi() {
      let lxxm = this.lianxi.getFieldValue("name");
      let lxfs = this.lianxi.getFieldValue("phone");
      let lxms = this.lianxi.getFieldValue("yuanyin");
      let xxdz = this.lianxi.getFieldValue("dizhi");
      getLianXi(
        this.$qs.stringify(this.CanShuJiHe(lxxm, lxfs, lxms, xxdz))
      ).then((res) => {
        if (res.data.BianHao == "0000") {
          this.lianxivis = false;
          this.$message.success("提交成功");
        }
      });
    },
    CanShuJiHe(lxxm, lxfs, lxms, xxdz) {
      let obj = {
        CanShuJi: JSON.stringify({
          YongHuCanShu: {},
          QiTaCanShu: {
            lxxm: lxxm,
            lxfs: lxfs,
            lxms: lxms,
            xxdz: xxdz,
            Id: 0,
            operation: "1",
          },
        }),
      };
      return obj;
    },
    afterLogin(res) {
      if (res.data.BianHao == "0000") {
        let xt_obj = res.data.JieGuo.QuanJuCanS[0];
        let yh_js = xt_obj.xt_jsms;
        let xt_yhid = xt_obj.xt_yhid;
        let xtYh = xt_obj.xt_yhms;
        let xtYhbm = xt_obj.xt_yhbm;
        let yh_toux = xt_obj.yh_toux;
        let xt_jsms = xt_obj.xt_jsms;

        localStorage.setItem("gw_yhid", xt_yhid);
        localStorage.setItem("gwYh", xtYh);
        localStorage.setItem("gwyhjs", yh_js);
        localStorage.setItem("gwYhbm", xtYhbm);
        localStorage.setItem("gw_jsms", xt_jsms);
        localStorage.setItem("yhtoux", yh_toux);
        this.sfdenglu = true;
        this.loginvis = false;
        this.$message.success("登录成功");
        this.HuoQuYongHu();
      } else {
        this.$message.error("用户名/密码不正确");
      }
    },
  },
};
</script>
<style scoped>
/* 顶部 */
#header {
  background: #f4f4f4;
  transition: all ease 0.6s;
  border: 0px;
}
#header .header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}
/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}
/* 导航栏 */
#header .header-nav {
  height: 110px;
}
/* 导航栏logo */
#header .header-nav .header-nav-logo {
  width: 200px;
  height: 100%;
  margin-left: 20%;
  float: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#header .header-nav .header-nav-logo span {
  font-size: 25px;
  font-weight: bolder;
  font-family: "幼圆";
}
/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: 65px;
  height: 65px;
  margin: auto;
}
/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}
#header .header-nav .header-nav-wrapper {
  line-height: 110px;
  float: left;
  margin: 0;
  padding: 0;
  margin-left: 3%;
  max-width: 800px;
}
/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper li {
  list-style-type: none;
  float: left;
  margin: 0 15px;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper li > span {
  color: #000;
  font-size: 17px;
  font-family: "幼圆";
  font-weight: bold;
  padding: 15px 0;
  position: relative;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper li > span > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #1e73be;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > span:hover {
  color: #1e73be;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > span:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > span {
  color: #1e73be;
  text-decoration: none;
  border-bottom: 2px solid #1e73be;
}
/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
}
/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}
#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
  background: #ccc;
}
.login {
  float: right;
  height: 100%;
  margin-right: 30px;
  display: flex;
  align-items: center;
  font-family: "幼圆";
}
.login p {
  font-size: 18px;
  margin: 0;
  cursor: pointer;
  font-family: "幼圆";
}
.tuichu {
  font-size: 16px;
  margin: 0;
  font-family: "幼圆";
}
.toux {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
