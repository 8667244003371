if(localStorage.getItem("gw_base")==null){
    let geoData = require('@/../public/config.json');
    localStorage.setItem('gw_base',geoData.base_Url);
    localStorage.setItem('gw_dzbd',geoData.dzbd_Url);
  }
let base_url = localStorage.getItem("gw_base");
let dzbd_Url = localStorage.getItem("gw_dzbd");
module.exports = {
    zhuyeData:`${base_url}/HomePage/GetMainData`,
    chanpinList: `${base_url}/HomePage/GetMenus`,
    chanpinDetail: `${base_url}/HomePage/GetContetnsByBM`,
    caidanList: `${base_url}/HomePage/GetBeforeMenus`,
    Token: `${base_url}/GG/HuoquToken`,
    getLOGIN: `${dzbd_Url}/Yun_dengl/ShouJiYongHuDengLu`,
    LianXi:`${base_url}/HomePage/OperationVisitor`
}