<template>
  <div id="app">
    <Header v-if="sftoken"></Header>
    <router-view v-if="sftoken" />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import { setAuthorization } from "@/utils/request";
import { getToken } from "./services/user";
export default {
  data() {
    return {
      sftoken: false,
    };
  },
  components: {
    Header,
  },
  mounted() {
    getToken()
      .then((res) => {
        if (res.data.BianHao == "0000") {
          let gtoken = res.data.JieGuo.access_token;
          this.sftoken = true;
          setAuthorization({
            token: gtoken,
          });
          // this.getZT();
        } else {
          this.$message.error("token获取失败");
        }
      })
      .catch((e) => {
      });
  },
};
</script>

<style lang="less">
body html {
  margin: 0;
  height: 100%;
}
#app {
  height: 100%;
}
</style>
