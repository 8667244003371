import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import axios from 'axios'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)

//富文本框
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

//轮播
import VueSwiper from 'vue-awesome-swiper';
Vue.use(VueSwiper)
import "swiper/css/swiper.min.css"

import qs from 'qs'
import {
  loadInterceptors
} from './utils/request'
import interceptors from './utils/axios-interceptors'
Vue.prototype.$qs = qs
Vue.config.productionTip = false
loadInterceptors(interceptors, {
  router,
  store
})

function SetConfig() {
  let info = Math.random() * 100;
  setTimeout(async () => {
     axios.get('./config.json?data=' + info).then((res) => {
      GetapiUrl(res);
    })
  }, 0)
}

function GetapiUrl(res) {
  localStorage.setItem("gw_base", res.data.base_Url);
  localStorage.setItem("gw_dzbd", res.data.dzbd_Url);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
SetConfig();