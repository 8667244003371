var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fuild",attrs:{"id":"header"}},[_c('div',{staticClass:"header-nav container hidden-xs"},[_c('div',{staticClass:"header-nav-logo",on:{"click":_vm.ClickLogo}},[_c('img',{attrs:{"src":require("@/assets/logo.png")}}),_c('span',[_vm._v("趣谷科技")])]),_c('ul',{staticClass:"header-nav-wrapper"},[_vm._l((_vm.navList),function(item,index){return [(item.Bm_HMXT_a040000 == 'SYBM')?_c('li',{key:index,class:item.Ms == _vm.navIndex ? 'active' : '',on:{"click":function($event){return _vm.navClick(index, item.Ms)}}},[_c('span',[_vm._v(" "+_vm._s(item.Ms)+" "),_c('i',{staticClass:"underline"})])]):_vm._e()]})],2),(!_vm.sfdenglu)?_c('div',{staticClass:"login"},[_c('p',{on:{"click":_vm.ShowLogin}},[_vm._v("登录")])]):_c('div',{staticClass:"login"},[_c('img',{staticClass:"toux",attrs:{"src":_vm.url + '/tupian/' + _vm.yhtx,"alt":""}}),_c('a-dropdown',{attrs:{"placement":'bottomCenter'}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"1"},[_c('p',{staticClass:"tuichu",on:{"click":_vm.TuiChuLogin}},[_vm._v("退出登录")])])],1),_c('p',[_vm._v(_vm._s(_vm.yhms))])],1)],1)]),_c('a-modal',{attrs:{"title":"登录","footer":null},model:{value:(_vm.loginvis),callback:function ($$v) {_vm.loginvis=$$v},expression:"loginvis"}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入账户名',
                  whitespace: true,
                } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入账户名',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","size":"large","placeholder":"请输入账号"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: '请输入密码',
                  whitespace: true,
                } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入密码',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"请输入密码","autocomplete":"autocomplete","type":"password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"size":"large","htmlType":"submit","type":"primary"}},[_vm._v("登录")])],1)],1)],1),_c('a-modal',{attrs:{"title":"联系我们","footer":null},model:{value:(_vm.lianxivis),callback:function ($$v) {_vm.lianxivis=$$v},expression:"lianxivis"}},[_c('a-form',{attrs:{"form":_vm.lianxi},on:{"submit":_vm.TiJiaoLianXi}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入姓名名',
                  whitespace: true,
                } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入姓名名',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","size":"small","placeholder":"联系姓名"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {
              rules: [
                {
                  required: true,
                  message: '请输入联系方式',
                  whitespace: true,
                } ],
            } ]),expression:"[\n            'phone',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入联系方式',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","size":"small","placeholder":"联系方式"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'yuanyin',
            {
              rules: [
                {
                  required: true,
                  message: '请输入联系原由',
                  whitespace: true,
                } ],
            } ]),expression:"[\n            'yuanyin',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入联系原由',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","size":"small","placeholder":"联系原由"}})],1),_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'dizhi',
            {
              rules: [
                {
                  required: false,
                  message: '请输入公司地址',
                  whitespace: true,
                } ],
            } ]),expression:"[\n            'dizhi',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: '请输入公司地址',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","size":"small","placeholder":"公司地址"}})],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"size":"large","htmlType":"submit","type":"primary"}},[_vm._v("提交")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }