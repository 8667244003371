import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import {
  Modal
} from "ant-design-vue";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    // component:()=>import('../views/Home.vue'),
    redirect: '/shouye'
  },
  {
    path: '/shouye',
    name: '主页',
    component: () => import('../views/ShouYe.vue')
  },
  {
    path: '/jishudangan',
    name: '技术档案',
    component: () => import('../views/Introduce.vue'),
    meta: {
      sfdl: true
    }
  },
  {
    path: '/hangyechanping',
    name: '行业产品介绍',
    component: () => import('../views/ChanPing.vue'),
    meta: {
      sfdl: true
    }
  },
  {
    path: '/about',
    name: '关于我们',
    component: () => import('../views/About.vue')
  },
]

const router = new VueRouter({
  routes
})
//全局前置守卫
router.beforeEach((to, from, next) => {
  let yhid = localStorage.getItem("gw_yhid");
  let yhms = localStorage.getItem("gwYh");
  if (to.meta.sfdl) {
    if (yhid && yhms) {
      next()
    } else {
      Modal.confirm({
        title: "对不起，您尚未获得授权，请留下联系方式，我们将尽快与您联系，感谢您的支持！",
        okText:"登录",
        cancelText:"联系我们",
        onOk() {
          Store.commit("setDenglu")
        },
        onCancel() {
          Store.commit("setLianxi")
        },
      })
    }
  } else {
    next();
  }
})
export default router