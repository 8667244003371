import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    denglu:false,
    lianxi:false
  },
  mutations: {
    setDenglu(state) {
      state.denglu = !state.denglu;
    },
    setLianxi(state) {
      state.lianxi = !state.lianxi;
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})